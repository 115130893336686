<template>
  <div id="OrderItemCell">
    <div class="columns is-vcentered">
      <div class="column">
        <div class="columns is-centered is-multiline">
          <div class="column is-12">
            <figure class="image is-128x128">
              <img :src="item.main_image" />
            </figure>
          </div>
          <div class="column is-12">
            <p>
              <b>{{ item.name }}</b>
            </p>
            <div class="column is-12" style="padding: 0;">
              <div class="columns is-multiline is-gapless is-mobile">
                <div class="column is-6">{{ $t("label.price") }}:</div>
                <div class="column is-6 has-text-right">
                  {{ item.price | thousandseparators }}
                  {{ $t("label.currency_unit") }}
                </div>
                <div class="column is-6">{{ $t("label.quantity") }}:</div>
                <div class="column is-6 has-text-right">
                  {{ item.quantity }}
                </div>
              </div>
            </div>
          </div>
          <div class="column is-12">
            <div class="columns is-multiline is-gapless is-mobile">
              <div class="column is-6">{{ $t("label.product_total") }}:</div>
              <div class="column is-6 has-text-right">
                {{ (item.price * item.quantity) | thousandseparators }}
                {{ $t("label.currency_unit") }}
              </div>
              <div class="column is-6">{{ $t("label.shipping") }}:</div>
              <div class="column is-6 has-text-right">
                <div v-if="current_step !== 0">
                  {{ (item.shipping * item.quantity) | thousandseparators }}
                  {{ $t("label.currency_unit") }}
                </div>
                <div v-else>-</div>
              </div>
              <div class="column is-6">{{ $t("label.total") }}:</div>
              <div class="column is-6 has-text-right">
                <div v-if="current_step !== 0">
                  {{
                    ((item.price + item.shipping) * item.quantity)
                      | thousandseparators
                  }}
                  {{ $t("label.currency_unit") }}
                </div>
                <div v-else>
                  {{ (item.price * item.quantity) | thousandseparators }}
                  {{ $t("label.currency_unit") }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Helper from "@/utils/helper";

export default {
  name: "OrderItemCell",
  props: ["item", "current_step"],
  filters: {
    thousandseparators: function(value) {
      return Helper.toThousandSeparatorsFormatter(value);
    }
  }
};
</script>

<style></style>
