import MainConnection from "./MainConnection";

export default {
  requestShippingMethods: async function() {
    try {
      const requestURL = "configs/shipping_rate";
      const response = await MainConnection.request().get(requestURL);
      return response.data;
    } catch (error) {
      MainConnection.errorMessage(error);
    }
  },
  requestSaveCheckoutState: async function(cart_id, params) {
    try {
      const requestURL = "/carts/" + cart_id + "/checkout";
      const response = await MainConnection.request().post(requestURL, params);
      return response.data;
    } catch (error) {
      MainConnection.errorMessage(error);
    }
  },
  requestChargeCreditCard: async function(cart_id, params) {
    try {
      const requestURL = "/carts/" + cart_id + "/charge";
      const response = await MainConnection.request().post(requestURL, params);
      return response.data;
    } catch (error) {
      MainConnection.errorMessage(error);
    }
  },
  requestChargeAlipay: async function(cart_id, return_uri) {
    try {
      const requestURL = "/carts/" + cart_id + "/charge";
      const alipay_params = {
        payment_type: "omise_alipay",
        payment: {
          return_uri
        }
      };
      const response = await MainConnection.request().post(
        requestURL,
        alipay_params
      );
      return response.data;
    } catch (error) {
      MainConnection.errorMessage(error);
    }
  },
  requestCheckPaymentStatus: async function(cart_id, params) {
    try {
      const requestURL = "/carts/" + cart_id + "/payment";
      const response = await MainConnection.request().post(requestURL, params);
      return response.data;
    } catch (error) {
      MainConnection.errorMessage(error);
    }
  }
};
