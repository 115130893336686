<template>
  <div id="PaymentMethodForm">
    <div v-if="reciever_info" class="columns is-multiline">
      <!-- RECEIVER NAME -->
      <div class="column is-12">
        <p v-if="reciever_info.full_name">
          {{ $t("checkout.step.payment.receiver") }} :
          {{ reciever_info.full_name }}
        </p>
        <p v-else>{{ $t("checkout.step.payment.receiver") }}: -</p>
      </div>
      <!-- PHONE NUMBER -->
      <div class="column is-12">
        <p v-if="reciever_info.phone">
          {{ $t("checkout.step.payment.phone") }}:
          {{ reciever_info.phone }}
        </p>
        <p v-else>{{ $t("checkout.step.payment.phone") }}: -</p>
      </div>

      <!-- RECEIVER EMAIL -->
      <div class="column is-12">
        <p v-if="reciever_info.email">
          {{ $t("checkout.step.payment.email") }}:
          {{ reciever_info.email }}
        </p>
        <p v-else>{{ $t("checkout.step.payment.email") }}: -</p>
      </div>

      <!-- RECEIVER ADDRESS -->
      <div class="column is-12">
        <p v-if="reciever_info.phone">
          {{ $t("checkout.step.payment.ship_to") }}:
          {{ reciever_info.ship_address }}
        </p>
        <p v-else>{{ $t("checkout.step.payment.ship_to") }}: -</p>
      </div>
    </div>
    <hr />
    <p class="subtitle is-5" style="margin-bottom: 0;">
      {{ $t("checkout.step.payment.payment_method") }}
    </p>
    <p class="subtitle is-6" style="color: #7a7a7a">
      {{ $t("checkout.step.payment.payment_remark") }}
    </p>
    <div class="columns is-multiline">
      <div class="column is-12">
        <div class="card" style="border-radius: 8px;">
          <div class="card-content">
            <div class="media">
              <div class="media-content">
                <div class="field">
                  <div class="columns">
                    <div class="column is-1">
                      <b-radio v-model="payment" native-value="omise" />
                    </div>
                    <div class="column">
                      <p class="title is-6">
                        {{
                          $t("checkout.step.payment.options.credit_card.title")
                        }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-12">
        <div class="card" style="border-radius: 8px;">
          <div class="card-content">
            <div class="media">
              <div class="media-content">
                <div class="field">
                  <div class="columns">
                    <div class="column is-1">
                      <b-radio v-model="payment" native-value="alipay" />
                    </div>
                    <div class="column">
                      <p class="title is-6">
                        {{ $t("checkout.step.payment.options.alipay.title") }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Back & Next Button -->
      <div class="column is-12">
        <div class="columns is-mobile">
          <div class="column has-text-left">
            <b-field>
              <b-button @click="backToShippingMethod">
                {{ $t("btn.back") }}
              </b-button>
            </b-field>
          </div>
          <div class="column has-text-right">
            <b-field>
              <b-button type="is-primary" @click="completeOrder">
                {{ $t("btn.complete_order") }}
              </b-button>
            </b-field>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "PaymentMethodForm",
  computed: {
    ...mapState(["cart"]),
    ...mapGetters(["reciever_info"])
  },
  data() {
    return {
      payment: "omise"
    };
  },
  methods: {
    backToShippingMethod: function() {
      this.$emit("back");
    },
    completeOrder: function() {
      this.$emit("completed", this.payment);
    }
  }
};
</script>

<style></style>
