<template>
  <div id="ShippingInfoForm">
    <p class="subtitle is-5" style="padding-top: 1rem">
      {{ $t("checkout.step.info_fill.shipping_address") }}
    </p>
    <div class="columns is-multiline">
      <!-- First Name + Last Name -->
      <div class="column is-12">
        <div class="columns is-mobile">
          <div class="column">
            <b-field>
              <b-input
                required
                v-model="firstName"
                :placeholder="$t('checkout.step.info_fill.firstname')"
              />
            </b-field>
          </div>
          <div class="column">
            <b-field>
              <b-input
                required
                v-model="lastName"
                :placeholder="$t('checkout.step.info_fill.lastname')"
              />
            </b-field>
          </div>
        </div>
      </div>
      <!-- Email -->
      <div class="column is-12">
        <b-field>
          <b-input
            required
            v-model="email"
            :placeholder="$t('checkout.step.info_fill.email')"
          />
        </b-field>
      </div>
      <!-- Phone / Contact Number -->
      <div class="column is-12">
        <b-field>
          <b-input
            required
            v-model="contactNumber"
            :placeholder="$t('checkout.step.info_fill.phone')"
          />
        </b-field>
      </div>
      <!-- Company Name -->
      <div class="column is-12">
        <b-field>
          <b-input
            v-model="companyName"
            :placeholder="$t('checkout.step.info_fill.company')"
          />
        </b-field>
      </div>
      <!-- Shipping Address -->
      <div class="column is-12">
        <b-field>
          <b-input
            required
            v-model="shippingAddress"
            :placeholder="$t('checkout.step.info_fill.address')"
          />
        </b-field>
      </div>
      <!-- Apartment, Suite, etc. -->
      <div class="column is-12">
        <b-field>
          <b-input
            v-model="accommodationType"
            :placeholder="$t('checkout.step.info_fill.accommodation')"
          />
        </b-field>
      </div>
      <!-- City -->
      <div class="column is-12">
        <div class="columns is-mobile">
          <div class="column">
            <b-field>
              <b-input
                required
                v-model="city"
                :placeholder="$t('checkout.step.info_fill.city')"
              />
            </b-field>
          </div>
          <div class="column">
            <b-field>
              <b-select
                expanded
                required
                v-model="country"
                :placeholder="$t('checkout.step.info_fill.country')"
              >
                <option
                  v-for="countryInfo in sorted_country"
                  :key="countryInfo.code"
                  :value="countryInfo"
                  >{{ countryInfo.country }}</option
                >
              </b-select>
            </b-field>
          </div>
        </div>
      </div>
      <!-- Sub-Country -->
      <div class="column is-12">
        <div class="columns is-mobile">
          <div class="column">
            <b-field>
              <b-select
                expanded
                required
                v-model="subcountry"
                :placeholder="$t('checkout.step.info_fill.sub_country')"
                :disabled="!shipping_subcountry.length > 0"
              >
                <option
                  v-for="subCountryInfo in shipping_subcountry"
                  :key="subCountryInfo.code"
                  :value="subCountryInfo"
                  >{{ subCountryInfo.name }}</option
                >
              </b-select>
            </b-field>
          </div>
          <div class="column">
            <b-field>
              <b-input
                required
                v-model="province"
                :placeholder="$t('checkout.step.info_fill.province')"
              />
            </b-field>
          </div>
          <div class="column">
            <b-field>
              <b-input
                required
                v-model="postalCode"
                type="number"
                :placeholder="$t('checkout.step.info_fill.postal')"
              />
            </b-field>
          </div>
        </div>
      </div>

      <div class="column is-12">
        <div class="columns is-mobile">
          <div class="column is-offset-8 has-text-right">
            <b-field>
              <b-button
                type="is-primary"
                :disabled="isDisableNextBtn"
                @click="validateForm"
              >
                {{ $t("btn.next") }}
              </b-button>
            </b-field>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Helper from "@/utils/helper";
import { mapState, mapMutations } from "vuex";
import CheckoutApiManager from "@/api/CheckoutApiManager";

export default {
  name: "ShippingInfoForm",
  data() {
    return {
      sorted_country: [],
      firstName: null,
      lastName: null,
      email: null,
      companyName: null,
      shippingAddress: null,
      accommodationType: null,
      city: null,
      country: null,
      subcountry: null,
      province: null,
      postalCode: null,
      contactNumber: null
    };
  },
  watch: {
    country: function(val) {
      if (val && !val.sub_country) {
        this.subcountry = null;
      }
    }
  },
  computed: {
    ...mapState(["cart"]),
    isDisableNextBtn: function() {
      if (
        this.firstName &&
        this.lastName &&
        this.email &&
        this.contactNumber &&
        this.shippingAddress &&
        this.city &&
        this.country &&
        this.province &&
        this.postalCode
      ) {
        if (this.country.sub_country) {
          return !(this.subcountry && this.subcountry.name);
        }
        return false;
      } else {
        return true;
      }
    },
    shipping_subcountry: function() {
      if (this.country && this.country.sub_country) {
        return this.country.sub_country;
      } else {
        return [];
      }
    }
  },
  methods: {
    ...mapMutations({
      setCart: "SET_CART"
    }),
    autoFillShippingInfo: function(contact_info) {
      this.firstName = contact_info.first_name;
      this.lastName = contact_info.last_name;
      this.email = contact_info.email;
      this.companyName = contact_info.company_name;
      this.shippingAddress = contact_info.address;
      this.accommodationType = contact_info.accommodation_type;
      this.city = contact_info.city;
      this.province = contact_info.province;
      this.postalCode = contact_info.postal_code;
      this.contactNumber = contact_info.contact_number;

      if (contact_info.country_code) {
        this.country = this.sorted_country.find(
          item => item.code === contact_info.country_code
        );
      }
      if (contact_info.sub_country_code) {
        this.subcountry = this.shipping_subcountry.find(
          item => item.code === contact_info.sub_country_code
        );
      }
    },
    validateForm: function() {
      if (!this.isDisableNextBtn) {
        const params = {
          first_name: this.firstName,
          last_name: this.lastName,
          email: this.email,
          contact_number: this.contactNumber,
          address: this.shippingAddress,
          city: this.city,
          province: this.province,
          postal_code: this.postalCode,
          country: this.country.country,
          country_code: this.country.code
        };
        if (this.companyName) {
          params.company_name = this.companyName;
        }

        if (this.accommodationType) {
          params.accommodation_type = this.accommodationType;
        }

        if (this.subcountry && this.subcountry.name) {
          params.sub_country = this.subcountry.name;
          params.sub_country_code = this.subcountry.code;
        }
        this.saveCheckoutOrderState(params);
      } else {
        this.$buefy.snackbar.open({
          duration: 5000,
          message: "Please completed every <b>required field</b> in the form.",
          type: "is-danger",
          actionText: "OK",
          queue: true
        });
      }
    },
    saveCheckoutOrderState: async function(checkout_params) {
      const contact = {
        contact: checkout_params
      };
      const loadingComponent = this.$buefy.loading.open({
        container: null
      });
      const response = await CheckoutApiManager.requestSaveCheckoutState(
        this.cart._id,
        contact
      );
      loadingComponent.close();

      switch (response.code) {
        case "0":
          this.setCart(response.data);
          this.$emit("next");
          break;

        default:
          this.$buefy.snackbar.open({
            message: "Error - " + response.message,
            type: "is-danger",
            actionText: "OK",
            queue: true
          });
          break;
      }
    }
  },
  mounted() {
    this.sorted_country = Helper.getShippingCountryList();
    if (this.cart && this.cart.contact) {
      this.autoFillShippingInfo(this.cart.contact);
    }
  }
};
</script>
