<template>
  <div id="CheckoutPage">
    <div class="section">
      <div class="container">
        <p class="title">{{ $t("checkout.title") }}</p>
        <div
          class="columns is-centered reverse-column-order"
          style="text-align: left; "
        >
          <!-- Checkout Step -->
          <div class="column is-6" style="margin-top: 1rem;">
            <b-steps
              type="is-primary"
              v-model="activeStep"
              :has-navigation="false"
            >
              <!-- STEP-1 : Info -->
              <b-step-item
                icon="account-plus"
                :label="$t('checkout.step.info_fill.title')"
              >
                <shipping-info-form @next="navigateToPayment" />
              </b-step-item>
              <!-- STEP-2 : Payment -->
              <b-step-item
                icon="cash-usd-outline"
                :label="$t('checkout.step.payment.title')"
              >
                <payment-method-form
                  @back="navigateToShippingInfo"
                  @completed="checkPaymentMethod"
                />
              </b-step-item>
            </b-steps>
          </div>

          <!-- Order Summary -->
          <div
            class="column is-4"
            style="background-color: lightgray; margin-top: 1rem; padding: 1.5rem;"
          >
            <!-- PRODUCT LIST -->
            <p class="title is-4" style="padding-top: 1rem;">
              {{ $t("checkout.order_summary") }}
            </p>
            <div v-for="(cartItem, index) in cart.products" :key="index">
              <order-item-cell :item="cartItem" :current_step="activeStep" />
              <hr />
            </div>

            <!-- SUMMARY CART -->
            <div class="columns is-multiline is-gapless is-mobile">
              <div class="column is-6">{{ $t("label.sub_total") }}:</div>
              <div class="column is-6 has-text-right">
                <p class="title is-6">
                  {{ subTotal | thousandseparators }}
                  {{ $t("label.currency_unit") }}
                </p>
              </div>
              <div class="column is-6">{{ $t("label.shipping") }}:</div>
              <div class="column is-6 has-text-right">
                <div v-if="activeStep !== 0">
                  <p v-if="shippingTotal > 0" class="title is-6">
                    {{ shippingTotal | thousandseparators }}
                    {{ $t("label.currency_unit") }}
                  </p>
                  <p v-else class="title is-6">
                    {{ $t("label.free") }}
                  </p>
                </div>
                <div v-else>
                  <p class="title is-6">
                    -
                  </p>
                </div>
              </div>
            </div>
            <hr />
            <div class="columns is-multiline is-mobile">
              <div class="column is-6">
                <p class="title is-5">{{ $t("label.total") }}</p>
              </div>
              <div class="column is-6 has-text-right">
                <p class="title is-5">
                  {{ total | thousandseparators }}
                  {{ $t("label.currency_unit") }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState, mapMutations } from "vuex";
import Config from "@/utils/conf";
import Helper from "@/utils/helper";
import CheckoutApiManager from "@/api/CheckoutApiManager";

import OrderItemCell from "@/components/OrderItemCell.vue";
import CreditCardForm from "@/components/forms/CreditCardForm.vue";
import ShippingInfoForm from "@/components/forms/ShippingInfoForm.vue";
import PaymentMethodForm from "@/components/forms/PaymentMethodForm.vue";

export default {
  name: "CheckoutPage",
  components: {
    OrderItemCell,
    ShippingInfoForm,
    PaymentMethodForm
  },
  data() {
    return {
      shippingInfo: null,
      shippingMethod: null,
      payment: null,
      activeStep: 0
    };
  },
  computed: {
    ...mapState(["cart"]),
    total: function() {
      let sum = this.subTotal;
      if (this.activeStep > 0) {
        sum += this.shippingTotal;
      }
      return sum;
    },
    subTotal: function() {
      var sum = this.cart.products.reduce(function(prev, cur) {
        return prev + cur.price * cur.quantity;
      }, 0);
      return sum;
    },
    shippingTotal: function() {
      var sum = this.cart.products.reduce(function(prev, cur) {
        return prev + cur.shipping * cur.quantity;
      }, 0);
      return sum;
    }
  },
  filters: {
    thousandseparators: function(value) {
      return Helper.toThousandSeparatorsFormatter(value);
    }
  },
  methods: {
    ...mapMutations({
      setCharge: "SET_CHARGE",
      removeCart: "REMOVE_CART"
    }),
    navigateToShippingInfo: function() {
      this.activeStep = 0;
    },
    navigateToPayment: function() {
      this.activeStep = 1;
    },
    checkPaymentMethod: function(payment_method) {
      switch (payment_method) {
        case "alipay":
          this.performChargeByAlipay();
          break;

        default:
          this.popupCreditcardForm();
          break;
      }
    },
    performChargeByAlipay: async function() {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      });
      const return_uri = Config.getCurrentURL() + "/cart/completed";
      const response = await CheckoutApiManager.requestChargeAlipay(
        this.cart._id,
        return_uri
      );
      switch (response.code) {
        case "0":
          this.removeCart();
          this.setCharge(response.data);
          window.location.href = response.data.redirect_url;
          break;

        default:
          break;
      }
      loadingComponent.close();
    },
    popupCreditcardForm: function() {
      this.$buefy.modal.open({
        parent: this,
        props: {
          city: this.cart.contact.city,
          postcode: this.cart.contact.postal_code
        },
        hasModalCard: true,
        component: CreditCardForm
      });
    }
  }
};
</script>

<style scoped>
.cell-item {
  padding-top: 1rem;
}
@media screen and (max-width: 769px) {
  .reverse-column-order {
    display: flex;
    flex-direction: column-reverse;
  }
}
</style>
