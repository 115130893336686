import axios from "axios";
import Config from "@/utils/conf";

export default {
  requestToken: async function(params) {
    const basicAuth = {
      auth: {
        username: Config.getOmiseKey(),
        password: ""
      }
    };
    const headers = {
      "Content-Type": "application/json"
    };
    const instance = axios.create({
      baseURL: "https://vault.omise.co",
      headers: headers
    });

    try {
      const requestURL = "/tokens";
      const response = await instance.post(requestURL, params, basicAuth);
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
};
