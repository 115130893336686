<template>
  <form id="CreditCardForm">
    <div class="modal-card fixed-width">
      <!-- Modal Title -->
      <header class="modal-card-head">
        <p class="modal-card-title">{{ $t("form_creditcard.title") }}</p>
      </header>
      <!-- Modal Description + Input -->
      <section class="modal-card-body" style="padding: 2.5rem 4rem;">
        <div class="media">
          <div class="media-content">
            <div class="columns is-multiline">
              <!-- Company Name -->
              <div class="column is-12">
                <b-field :label="$t('form_creditcard.card_no')">
                  <b-input
                    required
                    type="number"
                    maxlength="16"
                    v-model="cardNumber"
                    :has-counter="false"
                    :placeholder="$t('form_creditcard.card_no_placeholder')"
                  />
                </b-field>
              </div>
              <!-- Company Name -->
              <div class="column is-12">
                <b-field :label="$t('form_creditcard.name_on_card')">
                  <b-input
                    required
                    v-model="cardHolderName"
                    :placeholder="
                      $t('form_creditcard.name_on_card_placeholder')
                    "
                  />
                </b-field>
              </div>
              <!-- First Name + Last Name -->
              <div class="column is-12">
                <div class="columns is-mobile">
                  <div class="column">
                    <b-field :label="$t('form_creditcard.expired_date')">
                      <b-input
                        required
                        maxlength="7"
                        v-model="expiredDate"
                        :has-counter="false"
                        :placeholder="
                          $t('form_creditcard.expired_date_placeholder')
                        "
                      />
                    </b-field>
                  </div>
                  <div class="column">
                    <b-field>
                      <template slot="label">
                        {{ $t("form_creditcard.secure_code") }}
                        <b-tooltip
                          multilined
                          size="is-small"
                          type="is-dark"
                          :label="$t('form_creditcard.secure_code_info')"
                        >
                          <b-icon
                            size="is-small"
                            icon="help-circle-outline"
                          ></b-icon>
                        </b-tooltip>
                      </template>
                      <b-input
                        required
                        password-reveal
                        maxlength="3"
                        type="password"
                        v-model="ccv"
                        :has-counter="false"
                        :placeholder="
                          $t('form_creditcard.secure_code_placeholder')
                        "
                      />
                    </b-field>
                  </div>
                </div>
              </div>
              <!-- Company Name -->
              <div class="column is-12">
                <b-field>
                  <b-button
                    expanded
                    type="is-primary"
                    style="height: 2.5rem;"
                    @click="performCardCharge"
                    :disabled="disabledPayButton"
                  >
                    <p class="title is-5" style="color: white;">
                      <b>
                        {{ $t("label.pay") }}
                        {{ cart.price.total_price | thousandseparators }}
                        {{ $t("label.currency_unit") }}
                      </b>
                    </p>
                  </b-button>
                </b-field>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- Modal Footer -->
      <footer class="modal-card-foot" style="justify-content: center">
        <p>{{ $t("form_creditcard.remark") }}</p>
      </footer>
    </div>
  </form>
</template>

<script>
import Config from "@/utils/conf";
import Helper from "@/utils/helper";
import { mapState, mapMutations } from "vuex";
import OmiseApiManager from "@/api/OmiseApiManager";
import CheckoutApiManager from "@/api/CheckoutApiManager";

export default {
  name: "CreditCardForm",
  props: ["city", "postcode"],
  data() {
    return {
      cardNumber: null,
      cardHolderName: null,
      expiredDate: null,
      ccv: null
    };
  },
  computed: {
    ...mapState(["cart"]),
    disabledPayButton: function() {
      if (
        this.cardNumber &&
        this.cardHolderName &&
        this.expiredDate &&
        this.expiredDate.includes("/") &&
        this.ccv
      ) {
        return false;
      }
      return true;
    }
  },
  watch: {
    expiredDate: function(val) {
      if (val.length == 2) {
        this.expiredDate = val + "/";
      }
    }
  },
  filters: {
    thousandseparators: function(value) {
      return Helper.toThousandSeparatorsFormatter(value);
    }
  },
  methods: {
    ...mapMutations({
      setCharge: "SET_CHARGE",
      removeCart: "REMOVE_CART"
    }),
    performCardCharge: async function() {
      if (
        this.cardNumber &&
        this.cardHolderName &&
        this.expiredDate &&
        this.expiredDate.includes("/") &&
        this.ccv
      ) {
        this.getOmiseToken();
      }
    },
    getOmiseToken: async function() {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      });
      const mmyyyy = this.expiredDate.split("/");
      const params = {
        card: {
          name: this.cardHolderName,
          city: this.city,
          postal_code: this.postcode,
          number: this.cardNumber,
          security_code: this.ccv,
          expiration_month: mmyyyy[0],
          expiration_year: mmyyyy[1]
        }
      };

      const omiseResponse = await OmiseApiManager.requestToken(params);
      if (omiseResponse.code && omiseResponse.code === "invalid_card") {
        this.$buefy.snackbar.open({
          duration: 5000,
          message: "ERROR - " + omiseResponse.message,
          type: "is-danger",
          actionText: "OK",
          queue: true
        });
      } else {
        this.requestCharge(omiseResponse);
      }
      loadingComponent.close();
    },
    requestCharge: async function(omiseObj) {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      });
      const params = {
        payment_type: "omise_card",
        payment: {
          token_id: omiseObj.id,
          return_uri: Config.getCurrentURL() + "/cart/completed"
        }
      };
      const response = await CheckoutApiManager.requestChargeCreditCard(
        this.cart._id,
        params
      );
      loadingComponent.close();

      switch (response.code) {
        case "0":
          this.removeCart();
          this.setCharge(response.data);
          window.location.href = response.data.redirect_url;
          break;

        default:
          break;
      }
    }
  }
};
</script>

<style scoped>
.fixed-width {
  max-width: 480px;
}
</style>
